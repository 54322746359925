<div *ngIf="loadingProcess" class="container-loading">
  <div class="centered-div">
    <div class="loader"></div>
  </div>
</div>
@if(!loadingProcess) {
<section [ngClass]="{ 'overflow-hidden': !isSolutionScreenVisible() }">
  <div class="header">
    <app-header (displaySelectSolutionChanged)="toggleSolutionScreen($event)" />
  </div>
  <div class="wrapper">
    <div class="step-master">
      <app-step-master />
    </div>
    @if(!isSolutionScreenVisible()) {
    <div class="select-challenge-container">
      <div class="bg-w-25 bdr-8 challenges">
        <app-challenges-sub-challenges />
      </div>
      <div class="bg-w-25 bdr-8 selected-challenge">
        <app-selected-pair-challenges />
      </div>
    </div>
    } @else {
    <div class="select-solution-container">
      <div class="bg-w-25 bdr-8 input-solutions">
        <app-input-solutions />
      </div>
      <div class="bg-w-25 bdr-8 available-solutions">
        <app-available-solutions />
      </div>
    </div>
    }
  </div>
</section>
}
