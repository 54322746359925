import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';
import { Component, DestroyRef, inject, OnInit, signal } from '@angular/core';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import {
  AvailableSolutionBlockTitle,
  SolutionTitle,
} from 'src/app/core/common/constants/select-solution.enum';

import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { CheckboxModule } from 'primeng/checkbox';
import { setAvailableSolutionSelectedFlag } from 'src/app/core/store/actions/select-solution.action';
import { selectInputFields } from 'src/app/core/store/selectors/select-solution.selectors';

@Component({
  selector: 'app-available-solutions',
  standalone: true,
  imports: [
    ButtonModule,
    NgTemplateOutlet,
    CheckboxModule,
    FormsModule,
    NgClass,
    NgStyle,
  ],
  templateUrl: './available-solutions.component.html',
  styleUrl: './available-solutions.component.scss',
})
export class AvailableSolutionsComponent implements OnInit {
  #globalStore = inject(Store);
  #destroyRef = inject(DestroyRef);
  title = SolutionTitle.Title;
  availableSolutionTitle = AvailableSolutionBlockTitle;
  isEmptySolution = signal(true);

  // Temporary function for mock data support; will be removed after API integration.
  solutions: any;
  initialSolutions = [
    {
      solutionName: this.availableSolutionTitle.Tools,
      solutionItem: [
        { isChecked: false, name: 'Wash Nozzle' },
        { isChecked: false, name: 'Coilsweep' },
        { isChecked: false, name: 'Pulsonix' },
        { isChecked: false, name: 'Milling Tool' },
        { isChecked: false, name: 'Venturi Tool' },
      ],
    },
    {
      solutionName: this.availableSolutionTitle.Chemicals,
      solutionItem: [
        { isChecked: false, name: 'Aqualinear' },
        { isChecked: false, name: 'Biovert' },
        { isChecked: false, name: 'Logard' },
        { isChecked: false, name: 'N2' },
        { isChecked: false, name: 'N2 Foam' },
      ],
    },
    {
      solutionName: this.availableSolutionTitle.Conveyance,
      solutionItem: [
        { isChecked: false, name: 'CT' },
        { isChecked: false, name: 'HWO', isDisabled: false, tagName: '' },
        { isChecked: false, name: 'Spectrum' },
      ],
    },
  ];

  ngOnInit(): void {
    this.getAllInputSelected();
    this.solutions = cloneDeep(this.initialSolutions);
  }

  getAllInputSelected(): void {
    this.#globalStore
      .select(selectInputFields)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.isEmptySolution.set(!res.isAllFieldSelected);
      });
  }

  // Temporary function for mock data support; will be removed after API integration.
  unselectAllSolutions(): void {
    this.solutions = cloneDeep(this.initialSolutions);
    this.dispatchSelectSolutionFlag(false);
    this.solutions.map((v: any) => {
      v.solutionItem.forEach((i: any) => (i.isChecked = false));
    });
    document
      .querySelectorAll<HTMLInputElement>('.circle-overlay-front')
      .forEach((item) => (item.checked = false));
  }

  // Temporary function for mock data support; will be removed after API integration.
  onCheckboxChange(item: any): void {
    this.solutions.forEach((v: any) =>
      v.solutionItem.forEach((i: any) => {
        if (i.name === item.name) i.isChecked = !i.isChecked;
        if (i.name === 'HWO') i.isDisabled = true;
        if (['Spectrum', 'CT', 'Milling Tool'].includes(i.name))
          i.tagName = 'Synergy';
        if (item.name === 'Milling Tool') {
          if (['Aqualinear', 'Logard', 'Pulsonix'].includes(i.name)) {
            i.tagName = 'compatible';
          }
          if (['Biovert', 'N2', 'N2 Foam'].includes(i.name)) {
            i.tagName = 'not ideal';
          }
        }
      }),
    );
    this.dispatchSelectSolutionFlag(item.isChecked);
  }

  // Temporary function for mock data support; will be removed after API integration.
  dispatchSelectSolutionFlag(isChecked: boolean): void {
    this.#globalStore.dispatch(
      setAvailableSolutionSelectedFlag({
        isAvailableSolutionSelected: isChecked,
      }),
    );
  }
}
