import { NgClass, NgOptimizedImage } from '@angular/common';
import {
  Component,
  DestroyRef,
  inject,
  OnInit,
  output,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { ButtonModule } from 'primeng/button';
import { ButtonLabels } from 'src/app/core/common/constants/select-solution.enum';
import { setChallenges } from 'src/app/core/store/actions/select-challenge.actions';
import {
  setInputFields,
  setIsSelectSolutionFlag,
} from 'src/app/core/store/actions/select-solution.action';
import { selectPairChallenge } from 'src/app/core/store/selectors/select-challenge.selectors';
import { selectInputFields } from 'src/app/core/store/selectors/select-solution.selectors';
import { PairChallenge } from 'src/app/views/select-challenge/components/challenges-sub-challenges/challenges-sub-challenges.model';

@Component({
  selector: 'app-header',
  standalone: true,
  imports: [NgOptimizedImage, ButtonModule, NgClass],
  templateUrl: './header.component.html',
  styleUrl: './header.component.scss',
})
export class HeaderComponent implements OnInit {
  globalStore = inject(Store);
  pairChallenges = signal<PairChallenge[]>([]);
  #destroyRef = inject(DestroyRef);
  userName = '';
  displaySelectSolutionChanged = output<boolean>();
  isSelectSolution = signal(false);
  isDisableInitializeBtn = signal(true);
  isDisableViewSummaryBtn = signal(true);
  label = ButtonLabels;

  constructor(private authService: MsalService) {}

  ngOnInit(): void {
    this.getPairChallenge();
    this.getValidInputForms();
    this.getActiveAccountInfo();
  }

  getActiveAccountInfo(): void {
    const activeAccount = this.authService.instance.getActiveAccount();
    this.userName = activeAccount?.name ? activeAccount?.name : '';
  }

  logOut(): void {
    this.authService.logoutRedirect();
  }

  getPairChallenge(): void {
    this.globalStore
      .select(selectPairChallenge)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.pairChallenges.set(res.pairChallenges);
        this.isDisableInitializeBtn.set(!res.pairChallenges.length);
      });
  }

  // Temporary function for mock data support; will be removed after API integration.
  getValidInputForms(): void {
    this.globalStore
      .select(selectInputFields)
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe((res) => {
        this.isDisableViewSummaryBtn.set(!res.isAllFieldSelected);
      });
  }

  setDefaultChallenge(options: {
    isSelectSolution: boolean;
    isDisableInitializeBtn: boolean;
  }): void {
    this.isSelectSolution.set(options.isSelectSolution);
    this.isDisableInitializeBtn.set(options.isDisableInitializeBtn);
  }

  navigateToNextScreen(): void {
    this.displaySelectSolutionChanged.emit(true);
    this.setDefaultChallenge({
      isSelectSolution: true,
      isDisableInitializeBtn: true,
    });
    this.dispatchIsSelectSolutionFlag({ isEnable: true });
  }

  backToPreviousScreen(): void {
    this.displaySelectSolutionChanged.emit(false);
    this.setDefaultChallenge({
      isSelectSolution: false,
      isDisableInitializeBtn: false,
    });
  }

  restartWorkflow(): void {
    this.displaySelectSolutionChanged.emit(false);
    this.setDefaultChallenge({
      isSelectSolution: false,
      isDisableInitializeBtn: true,
    });
    this.resetAllWorkflowStates();
    this.dispatchIsSelectSolutionFlag({ isEnable: false });
  }

  resetAllWorkflowStates(): void {
    this.globalStore.dispatch(
      setChallenges({
        pairChallenges: [],
      }),
    );

    this.globalStore.dispatch(
      setInputFields({
        isAllFieldSelected: false,
      }),
    );
  }

  dispatchIsSelectSolutionFlag(options: { isEnable: boolean }) {
    this.globalStore.dispatch(
      setIsSelectSolutionFlag({
        isIsSelectSolutionFlag: options.isEnable,
      }),
    );
  }
}
